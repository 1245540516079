
import { FormGroup, Box, Table, TableCell, TableBody,TableRow,TableContainer,TableHead,Paper, Grid, TextField, Card, CardContent, MenuItem, FormControl, InputLabel, Select, Checkbox, FormControlLabel, Typography, RadioGroup, Radio, Button } from '@mui/material';
import { useNavigate,useParams } from 'react-router-dom';
import React, { useEffect } from 'react';


const TransferRequestForm = () => {
  
  const [parentCategories, setParentCategories] = React.useState({
    Cardio: false,
    GI: false,
    Neuro: false
  });
  
  const subcategories = {
    Cardio: ['Interventional Cardiology', 'Noninvasive Cardiology', 'ElectroPhysiology', 'Cardiogenic Shock', 'TAVR', 'Other'],
    Neuro: ['Stroke/TIA', 'Epilepsy', 'Movement Disorder', 'Encephalopathy', 'Other'],
    GI: ['Colonoscopy', 'ERCP', 'Hepatitis', 'Liver Transplant', 'Other']
  };
  const navigate = useNavigate();  // React Router's navigation hook
  const { id } = useParams();  // Get ID from route parameters if you have routing set up (e.g., /transferform/:id)
  
  // API URL for your backend
  //const apiUrl = "https://localhost:44304/api/transferform";
  const apiUrl = "https://jbh-fhir.azurewebsites.net/api/transferform";
 
 // New Requested Services Section State  
 const [requestedServices, setRequestedServices] = React.useState([]);

 const medicalOptions = ['Cardio', 'GI', 'ID', 'Nephro', 'Neuro', 'Onc', 'Pulm', 'Psych', 'Other'];
 const surgicalOptions = ['CT Surg', 'Gen Surg', 'Ortho', 'Urology', 'Vascular', 'Neuro Surg'];
 const procedureOptions = ['IR', 'CT', 'MRI', 'EEG', 'EMU', 'Cardio (TEE, TAVR)', 'Branch (EGD, Colo)'];
 const otherOptions = ['PT', 'OT', 'ST', 'Burn', 'Transplant'];

  const serviceCategories = [
    { type: 'Medical', options: medicalOptions },
    { type: 'Surgical', options: surgicalOptions },
    { type: 'Procedure/Imaging', options: procedureOptions },
    { type: 'Other', options: otherOptions }
  ];

 const handleServiceChange = (event) => {
   const value = event.target.value;
     
    setRequestedServices((prev) =>
      prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
    );
  
 };

 const getServiceOptions = (option) => {
  if(!option === undefined){
    switch (selectedTab) {
      case 0:
        return medicalOptions;
      case 1:
        return surgicalOptions;
      case 2:
        return procedureOptions;
      case 3:
        return otherOptions;
      default:
        return [];
  }
}
  switch (selectedTab) {
    case 0:
      return medicalOptions;
    case 1:
      return surgicalOptions;
    case 2:
      return procedureOptions;
    case 3:
      return otherOptions;
    default:
      return [];
  }
};

  // Transfer Request Section State
  const [patientLocation, setPatientLocation] = React.useState('');
  const [facility, setFacility] = React.useState('');
  const [contactNumber, setContactNumber] = React.useState('');
  const [rnNumber, setRnNumber] = React.useState('');
  const [doctorNumber, setDoctorNumber] = React.useState('');
  const [transferType, setTransferType] = React.useState('lowToHigh');
  const [adult, setAdult] = React.useState(true);
  const [oneWay, setOneWay] = React.useState(true);
  const [telemedConsult, setTelemedConsult] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState(0); // New state to track selected tab
  
  // Transport Needs Section State
  const [transportGroundAls, setTransportGroundAls] = React.useState(false);
  const [transportGroundBls, setTransportGroundBls] = React.useState(false);
  
  const [transportGroundMed, setTransportGroundMed] = React.useState(false);
  const [transportGroundPov, setTransportGroundPov] = React.useState(false);
  const [transportAir, setTransportAir] = React.useState(false);
  const [specialHandling, setSpecialHandling] = React.useState(false);
  const [isolation, setIsolation] = React.useState(false);
  const [restraintViolent, setRestraintViolent] = React.useState(false);
  const [restraintNonViolent, setRestraintNonViolent] = React.useState(false);
  const [positioning, setPositioning] = React.useState(false);
  const [vip, setVip] = React.useState(false);
  const [equipment, setEquipment] = React.useState([]);
  const [enRouteMeds, setEnRouteMeds] = React.useState([]);

  // Demographics Section State
  const [name, setName] = React.useState('');
  const [dob, setDob] = React.useState('');
  const [dateAdmitted, setDateAdmitted] = React.useState('');
  const [codeStatus, setCodeStatus] = React.useState('');
  const [insurance, setInsurance] = React.useState('');
  const [familyContact, setFamilyContact] = React.useState('');

  // Communication Section State
  const [bedType, setBedType] = React.useState('');
  const [facilityMatch, setFacilityMatch] = React.useState('');
  const [requestedInfo, setRequestedInfo] = React.useState([]);
  const [communicationLog, setCommunicationLog] = React.useState('');

  const handleEquipmentChange = (event) => {
    const value = event.target.value;
    let newEq = equipment;
    newEq[value] = !newEq[value];
    setEquipment(newEq);
  };

  const handleEnRouteMedsChange = (event) => {
    const value = event.target.value;
    setEnRouteMeds((prev) => prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]);
  };

  const handleRequestedInfoChange = (event) => {
    const value = event.target.value;
    setRequestedInfo((prev) => prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]);
  };

  const handleScrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  
  const checkServices = (services, val) => {
    return services.indexOf(val) >= 0; // Reset services when tab changes
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    // Construct the data object to match the C# model
    const transferFormData = {
      transferRequest: {
        patientLocation,
        facility,
        contactNumber,
        rnNumber,
        doctorNumber,
        transferType,
        patientType: adult ? "adult" : "pediatric",
        tripRequest: {
          oneWay,
          roundTrip: !oneWay,
          telemedConsult
        }
      },
      transportNeeds: {
        ground: {
          ALS: transportGroundAls,
          BLS: transportGroundBls,
          Medium: transportGroundMed,
          POV: transportGroundPov
        },
        air: {
          fixed: transportAir,
          rotary: false
        },
        specialHandling: {
          specialHandling: specialHandling,
          isolation: isolation,
          restraintViolent: restraintViolent,
          restraintNonViolent: restraintNonViolent,
          positioning: positioning,
          vipTrauma: vip,
          other: false
        },
        equipment: {
          O2: equipment.o2,
          VentBiPapCPap: equipment.vent,
          LinesDrains: equipment.lines,
          Impella: equipment.impella,
          Other: equipment.other
        },
        enRouteMeds: {
          medication1: enRouteMeds.med1,
          medication2: enRouteMeds.med2,
          medication3: enRouteMeds.med3
        }
      },
      demographics: {
        name,
        dob: new Date(dob),
        dateAdmitted: new Date(dateAdmitted),
        codeStatus,
        insurance,
        familyContact
      },
      communication: {
        bedType,
        facilityMatch,
        requestedInfo: {
          faceSheet: requestedInfo.faceSheet,
          pushImages: requestedInfo.pushImages,
          mdToCall: requestedInfo.mdToCall
        },
        communicationLog
      },
      requestedServices: {
        medical: JSON.stringify(requestedServices.filter(service => medicalOptions.includes(service))),
        surgical: JSON.stringify(requestedServices.filter(service => surgicalOptions.includes(service))),
        procedureImaging: JSON.stringify(requestedServices.filter(service => procedureOptions.includes(service))),
        other: JSON.stringify(requestedServices.filter(service => otherOptions.includes(service)))
      }
    };

    try {
      // Send data to API
      let apiUrl = "https://localhost:44304/api/transferform"
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(transferFormData)
      });

      if (response.ok) {
        console.log("Transfer form submitted successfully");
        navigate('/hospital-list'); // Redirect or handle success
      } else {
        console.error("Failed to submit transfer form");
      }
    } catch (error) {
      console.error("An error occurred while submitting the form", error);
    }
  };


    // Fetch transfer form data from the API
    const fetchTransferForm = async () => {
      if (!id) return;  // Only fetch if there is an ID
  
      try {
        const response = await fetch(`${apiUrl}/${id}`);
        if (response.ok) {
          const data = await response.json();
          
          // Update state with retrieved data
          setPatientLocation(data.transferRequest.patientLocation || '');
          setFacility(data.transferRequest.facility || '');
          setContactNumber(data.transferRequest.contactNumber || '');
          setRnNumber(data.transferRequest.rnNumber || '');
          setDoctorNumber(data.transferRequest.doctorNumber || '');
          setTransferType(data.transferRequest.transferType || 'lowToHigh');
          setAdult(data.transferRequest.patientType === 'adult');
          setOneWay(data.transferRequest.tripRequest?.oneWay);
          setTelemedConsult(data.transferRequest.tripRequest?.telemedConsult);
          
          // Transport Needs
          setTransportGroundAls(data.transportNeeds.ground.als || false);
          setTransportGroundBls(data.transportNeeds.ground.bls || false);
          
          setTransportGroundMed(data.transportNeeds.ground.medium || false);
          setTransportGroundPov(data.transportNeeds.ground.pov || false);
          setTransportAir(data.transportNeeds.air.fixed || false);
          setSpecialHandling(data.transportNeeds.specialHandling.specialHandling || false);
          setIsolation(data.transportNeeds.specialHandling.isolation || false);
          setRestraintViolent(data.transportNeeds.specialHandling.restraintViolent || false);
          setRestraintNonViolent(data.transportNeeds.specialHandling.restraintNonViolent || false);
          setPositioning(data.transportNeeds.specialHandling.positioning || false);
          setVip(data.transportNeeds.specialHandling.vipTrauma || false);
          setEquipment(data.transportNeeds.equipment || []);
          setEnRouteMeds(data.transportNeeds.enRouteMeds || []);
          
          // Demographics
          setName(data.demographics.name || '');
          setDob(data.demographics.dob || '');
          setDateAdmitted(data.demographics.dateAdmitted || '');
          setCodeStatus(data.demographics.codeStatus || '');
          setInsurance(data.demographics.insurance || '');
          setFamilyContact(data.demographics.familyContact || '');
  
          // Communication
          setBedType(data.communication.bedType || '');
          setFacilityMatch(data.communication.facilityMatch || '');
          setRequestedInfo(data.communication.requestedInfo || []);
          setCommunicationLog(data.communication.communicationLog || '');
          let stuff = [];
          stuff.push(...JSON.parse(data.requestedServices.medical));
          stuff.push(...JSON.parse(data.requestedServices.surgical));
          stuff.push(...JSON.parse(data.requestedServices.procedureImaging   ));
          stuff.push(...JSON.parse(data.requestedServices.other   ));
          // Requested Services
          setRequestedServices(stuff);
        } else {
          console.error("Failed to fetch transfer form data");
        }
      } catch (error) {
        console.error("An error occurred while fetching the transfer form", error);
      }
    };
  
    // Fetch data when component mounts if there's an ID
    useEffect(() => {
      fetchTransferForm();
    }, [id]);


  return (
    <Box  display="flex" height="90vh" overflow="hidden">
      {/* Navigation Sidebar */}
  <Box       flex="0 0 230px"
        bgcolor="#f0f0f0"
        p={2}
        position="sticky"
        top="0" // Keep the sidebar fixed at the top of the viewport
        height="90vh" // Ensure the sidebar takes up the full height of the page
   >
  <Typography variant="h6">Sections</Typography>
        <Button onClick={() => handleScrollToSection('transfer-request')} fullWidth sx={{ marginBottom: 1 }}>
          A. Transfer Request
        </Button>
        <Button onClick={() => handleScrollToSection('transport-needs')} fullWidth sx={{ marginBottom: 1 }}>
          B. Transport Needs
        </Button>
        <Button onClick={() => handleScrollToSection('demographics')} fullWidth sx={{ marginBottom: 1 }}>
          C. Demographics
        </Button>
        <Button onClick={() => handleScrollToSection('communication')} fullWidth sx={{ marginBottom: 1 }}>
          D. Communication
        </Button>
        <Button onClick={() => handleScrollToSection('requested-services')} fullWidth>
          E. Requested Services
        </Button>
      </Box>
      {/* Form Section */}
      <Box         flex="1"
        p={2}
        overflow="auto" // Make this section scrollable
        height="90vh" // Ensure it fills the vertical space
        >        
        <Card id="transfer-request" sx={{ marginBottom: -4 }}>
          <CardContent>
            <Typography variant="h5">Transfer Request</Typography>

            <Grid container spacing={0}>
              {/* Patient Location */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    label="Patient Location"
                    value={patientLocation}
                    onChange={(e) => setPatientLocation(e.target.value)}
                    placeholder="Name of Facility (Dropdown to complete)"
                    required
                  />
                </FormControl>
              </Grid>

              {/* Facility */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    label="Facility"
                    value={facility}
                    onChange={(e) => setFacility(e.target.value)}
                    required
                  />
                </FormControl>
              </Grid>

              {/* Contact Number */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    label="Contact Number"
                    value={contactNumber}
                    onChange={(e) => setContactNumber(e.target.value)}
                    required
                  />
                </FormControl>
              </Grid>

              {/* RN Number */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    label="RN Number"
                    value={rnNumber}
                    onChange={(e) => setRnNumber(e.target.value)}
                    required
                  />
                </FormControl>
              </Grid>

              {/* Doctor Number */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    label="Doctor Number"
                    value={doctorNumber}
                    onChange={(e) => setDoctorNumber(e.target.value)}
                    required
                  />
                </FormControl>
              </Grid>

              {/* Transfer Type */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="dense">
                  <InputLabel>Transfer Type</InputLabel>
                  <Select
                    value={transferType}
                    onChange={(e) => setTransferType(e.target.value)}
                  >
                    <MenuItem value="lowToHigh">Low to High (Default)</MenuItem>
                    <MenuItem value="lateral">Lateral</MenuItem>
                    <MenuItem value="highToLow">High to Low</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Patient Type */}
              <Grid item xs={12} sm={6}>
                
              <Box border={1} borderColor="grey.300" borderRadius={1} p={2}>
                <FormControl component="fieldset" margin="dense">
                  <RadioGroup
                    row
                    value={adult ? 'adult' : 'pediatric'}
                    onChange={(e) => setAdult(e.target.value === 'adult')}
                  >
                    <FormControlLabel value="adult" control={<Radio />} label="Adult" />
                    <FormControlLabel value="pediatric" control={<Radio />} label="Pediatric" />
                  </RadioGroup>
                </FormControl>
                </Box>
              </Grid>

              {/* Transfer Details */}
              <Grid item xs={12} sm={6}>
                
              <Box border={1} borderColor="grey.300" borderRadius={1} p={2}>
                <FormControl component="fieldset" margin="dense" variant="outlined">
                  <FormGroup row="true">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={oneWay}
                        onChange={(e) => setOneWay(e.target.checked)}
                      />
                    }
                    label="Oneway"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!oneWay}
                        onChange={(e) => setOneWay(!e.target.checked)}
                      />
                    }
                    label="Roundtrip"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={telemedConsult}
                        onChange={(e) => setTelemedConsult(e.target.checked)}
                      />
                    }
                    label="Tele"
                  />
                  </FormGroup>
                </FormControl>
                </Box>
              </Grid>
            </Grid>

          </CardContent>
          </Card>

          <Card id="transport-needs" sx={{ marginBottom: -4 }}>
            <CardContent>
            <Typography variant="h5">Transport Needs</Typography>

            <Grid container spacing={0}>
              {/* Ground Transportation */}
              <Grid item xs={12} sm={6}>
                
              <Box border={1} borderColor="grey.300" borderRadius={1} p={2}>
                <Typography variant="subtitle1">Ground</Typography>
                <FormControlLabel
                  control={<Checkbox checked={transportGroundAls} onChange={(e) => setTransportGroundAls(e.target.checked)} />}
                  label="ALS"
                />
                <FormControlLabel
                  control={<Checkbox checked={transportGroundBls}  onChange={(e) => setTransportGroundBls(e.target.checked)}/>}
                  label="BLS"
                />
                <FormControlLabel
                  control={<Checkbox checked={transportGroundMed}  onChange={(e) => setTransportGroundMed(e.target.checked)}/>}
                  label="Medium"
                />
                <FormControlLabel
                  control={<Checkbox checked={transportGroundPov}  onChange={(e) => setTransportGroundPov(e.target.checked)}/>}
                  label="POV"
                />
                </Box>
              </Grid>

              {/* Air Transportation */}
              <Grid item xs={12} sm={6}>
              <Box border={1} borderColor="grey.300" borderRadius={1} p={2}>
                <Typography variant="subtitle1">Air</Typography>
                <FormControlLabel
                  control={<Checkbox checked={transportAir} onChange={(e) => setTransportAir(e.target.checked)} />}
                  label="Fixed"
                />
                <FormControlLabel
                  control={<Checkbox checked={false} />}
                  label="Rotary"
                />
                </Box>
              </Grid>

              {/* Special Handling */}
              <Grid item xs={12} sm={6}>
                
              <Box border={1} borderColor="grey.300" borderRadius={1} p={2}>
                <Typography variant="subtitle1">Special Handling</Typography>
                <FormControlLabel
                  control={<Checkbox checked={specialHandling} onChange={(e) => setSpecialHandling(e.target.checked)} />}
                  label="Special Handling"
                />
                <FormControlLabel
                  control={<Checkbox checked={isolation} onChange={(e) => setIsolation(e.target.checked)} />}
                  label="Isolation"
                />
                <FormControlLabel
                  control={<Checkbox checked={restraintViolent} onChange={(e) => setRestraintViolent(e.target.checked)} />}
                  label="Restraint - Violent"
                />
                <FormControlLabel
                  control={<Checkbox checked={restraintNonViolent} onChange={(e) => setRestraintNonViolent(e.target.checked)} />}
                  label="Restraint - Non Violent"
                />
                <FormControlLabel
                  control={<Checkbox checked={positioning} onChange={(e) => setPositioning(e.target.checked)} />}
                  label="Positioning"
                />
                <FormControlLabel
                  control={<Checkbox checked={vip} onChange={(e) => setVip(e.target.checked)} />}
                  label="VIP/Trauma"
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Other"
                />
                </Box>
              </Grid>

              {/* Equipment */}
              <Grid item xs={12} sm={6}>
                
              <Box border={1} borderColor="grey.300" borderRadius={1} p={2}>
                <Typography variant="subtitle1">Equipment</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="o2"
                      checked={equipment.o2}
                      onChange={handleEquipmentChange}
                    />
                  }
                  label="O2"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="vent"
                      checked={equipment.vent}
                      onChange={handleEquipmentChange}
                    />
                  }
                  label="Vent/Bi-pap/c-pap"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="lines"
                      checked={equipment.lines}
                      onChange={handleEquipmentChange}
                    />
                  }
                  label="Lines/Drains"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="impella"
                      checked={equipment.impella}
                      onChange={handleEquipmentChange}
                    />
                  }
                  label="Impella"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="other"
                      checked={equipment.other}
                      onChange={handleEquipmentChange}
                    />
                  }
                  label="Other"
                />
                </Box>
              </Grid>

              {/* En Route Meds */}
              <Grid item xs={12} sm={6}>
                
              <Box border={1} borderColor="grey.300" borderRadius={1} p={2}>
                <Typography variant="subtitle1">En Route Meds</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="Med1"
                      checked={enRouteMeds.medication1}
                      onChange={handleEnRouteMedsChange}
                    />
                  }
                  label="Medication 1"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="Med2"
                      checked={enRouteMeds.medication2}
                      onChange={handleEnRouteMedsChange}
                    />
                  }
                  label="Medication 2"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="Med3"
                      checked={enRouteMeds.medication3}
                      onChange={handleEnRouteMedsChange}
                    />
                  }
                  label="Medication 3"
                />
                </Box>
              </Grid>
            </Grid>
            </CardContent>
          </Card>

          <Card id="demographics" sx={{ marginBottom: -4 }}>
            <CardContent>
            <Typography variant="h5">Demographics</Typography>

            <Grid container spacing={0}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    label="Date of Birth (DoB)"
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                    required
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    label="Date Admitted"
                    value={dateAdmitted}
                    onChange={(e) => setDateAdmitted(e.target.value)}
                    required
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    label="Code Status"
                    value={codeStatus}
                    onChange={(e) => setCodeStatus(e.target.value)}
                    required
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    label="Insurance"
                    value={insurance}
                    onChange={(e) => setInsurance(e.target.value)}
                    required
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    label="Family Contact"
                    value={familyContact}
                    onChange={(e) => setFamilyContact(e.target.value)}
                    required
                  />
                </FormControl>
              </Grid>
            </Grid>
            </CardContent>
            </Card>
   
            
          <Card id="communication" sx={{ marginBottom: -4 }}>
            <CardContent>
            <Typography variant="h5">Communication</Typography>

            <Grid container spacing={0}>
              {/* Bed Type */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="dense">
                  <InputLabel>Bed Type</InputLabel>
                  <Select
                    value={bedType}
                    onChange={(e) => setBedType(e.target.value)}
                  >
                    <MenuItem value="medSurg">Med/Surg</MenuItem>
                    <MenuItem value="tele">TELE</MenuItem>
                    <MenuItem value="stepDown">Stepdown</MenuItem>
                    <MenuItem value="icu">ICU</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Facility Match */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="dense">
                  <InputLabel>Facility Match</InputLabel>
                  <Select
                    value={facilityMatch}
                    onChange={(e) => setFacilityMatch(e.target.value)}
                  >
                    <MenuItem value="hospitalA">Hospital A</MenuItem>
                    <MenuItem value="hospitalB">Hospital B</MenuItem>
                    <MenuItem value="hospitalC">Hospital C</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Requested Info */}
              <Grid item xs={12}>
                <Typography variant="subtitle1">Requested Information</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="faceSheet"
                      checked={requestedInfo.faceSheet}
                      onChange={handleRequestedInfoChange}
                    />
                  }
                  label="Face Sheet"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="pushImages"
                      checked={requestedInfo.pushImages}
                      onChange={handleRequestedInfoChange}
                    />
                  }
                  label="Push Images"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="mdToCall"
                      checked={requestedInfo.mdToCall}
                      onChange={handleRequestedInfoChange}
                    />
                  }
                  label="MD to Call"
                />
                {/* Additional checkboxes can be added here as needed */}
              </Grid>

              {/* Communication Log */}
              <Grid item xs={12}>
                <TextField
                  label="Communication Log"
                  multiline
                  rows={4}
                  fullWidth
                  value={communicationLog}
                  onChange={(e) => setCommunicationLog(e.target.value)}
                />
              </Grid>
            </Grid>

            </CardContent>
            </Card>
          
        {/* Existing sections like Transfer Request, Transport Needs, Demographics, Communication here */}

          
          <Card id="requested-services" sx={{ marginBottom: -4 }}>
            <CardContent>
            <Typography variant="h5">Requested Services</Typography>
          
            <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><Typography variant="subtitle1">Service Type</Typography></TableCell>
                <TableCell colSpan={4}><Typography variant="subtitle1">Options</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {serviceCategories.map((category) => (
                <TableRow key={category.type}>
                  <TableCell>
                    <Typography variant="body1">{category.type}</Typography>
                  </TableCell>
                  {category.options.map((service) => (
                    <TableCell key={service}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={service}
                            checked={checkServices(requestedServices, service)}
                            onChange={handleServiceChange}
                          />
                        }
                        label={service}
                      />
                      {subcategories[service] && checkServices(requestedServices, service) && (
                        <Box sx={{ marginLeft: 2 }}>
                          {subcategories[service].map((subcat) => (
                            <FormControlLabel
                            style={{ width: '100%' }}
                           control={
                             <Checkbox
                               value={subcat}
                               onChange={handleServiceChange}
                             />
                           }
                           label={subcat}
                         />
                          ))}
                           
                         </Box>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
            {/* Submit Button */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </CardContent>
          </Card>
      </Box>
    </Box>
  );
};

export default TransferRequestForm;
